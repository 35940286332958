.domain-cn {
  .site-header {
    .site-header__menu {
      @if $fonts_update == false {
        font-family: 'Microsoft YaHei', Helvetica, Arial, Sans-Serif;
      }
    }
    .site-email-signup {
      &__contents-form {
        #form--email_sms_signup--field--MOBILE_NUMBER {
          display: none;
        }
      }
    }
  }
  #site-header {
    .site-header {
      &__prefix {
        width: 10%;
      }
      &__menu {
        width: 65%;
      }
      &__suffix {
        width: 20%;
      }
    }
    .site-navigation {
      &__item {
        padding: 0 15px;
      }
    }
  }
}

mtop {
  padding-top: 10px;
}

.infoBox > img {
  display: none;
}

.infoBox > div {
  margin-bottom: 15px;
}

#cboxWrapper {
  select::-ms-expand {
    display: none;
  }
  #cboxContent {
    .icon--remove {
      padding-right: 14px;
    }
    .address-form {
      .address-title {
        label {
          padding-right: 8px;
        }
        input[type='radio'] {
          margin-top: 3px;
          margin-right: 5px;
        }
      }
      select {
        text-decoration: none;
        -moz-appearance: none;
      }
    }
  }
}

.site-footer {
  .footer-menu--pc {
    .grid--item:nth-of-type(6n + 2) {
      width: 19.66667%;
      margin-left: 19.66667%;
    }
    .grid--item:nth-of-type(6n + 3) {
      width: 19.66667%;
      margin-left: 39.33333%;
    }
    .grid--item:nth-of-type(6n + 4) {
      width: 19.66667%;
      margin-left: 59%;
    }
    .grid--item:nth-of-type(6n + 5) {
      width: 19.66667%;
      margin-left: 78.66667%;
    }
    li {
      a {
        text-decoration: none;
        display: inline;
      }
    }
    .default_store_data {
      .find_store_link {
        margin: 0 0 5px 0;
      }
    }
  }
}

.site-container {
  #product-results {
    .product--orderable {
      &:not(.prod_inv_status-1) {
        .hide-non-orderable-product {
          display: none;
        }
      }
      .product__link-custom {
        display: none;
      }
    }
  }
  .page--spp-ultra-wide {
    .product__footer {
      .product__inventory-status {
        a.notify_me {
          padding: 18px 0;
          width: 100%;
          position: static;
          font-size: 1.5em;
        }
      }
    }
    .product__social-links {
      height: 20px;
      width: 6.1em;
      .icon--weibo {
        background: url(/media/export/cms/weibo_icon.svg) no-repeat center;
        width: 18px;
        height: 18px;
      }
      .product__social-link--weixin {
        .qrcodeTableHidden {
          display: none;
          position: absolute;
          padding: 20px;
          bottom: 25px;
          #{$ldirection}: -190px;
          height: auto;
          z-index: 999;
          cursor: pointer;
          background-color: white;
          box-shadow: 0 0 3px grey;
          h3 {
            font-size: 14px;
          }
        }
        .icon--weixin {
          background: url(/media/export/cms/weixin_icon.svg) no-repeat center;
          width: 18px;
          height: 18px;
        }
      }
      .icon--renren {
        background: url(/media/export/cms/renren_icon.svg) no-repeat center;
        width: 16px;
        height: 16px;
      }
    }
  }
  .grid--mpp {
    &__item {
      .product--not-orderable {
        .product__footer {
          .product__add-to-bag.coming-soon,
          .product__link-to-spp {
            display: none;
          }
          li.temp-out-of-stock {
            padding-top: 0px;
          }
        }
      }
      .product__header {
        .product__name {
          height: 60px;
        }
      }
    }
  }
  .multi-use-tout--align-raised.multi-use-tout__has-copy {
    .multi-use-tout__caption-inner-inner {
      left: 25% !important;
      margin: 0 auto !important;
      padding: 4% 15px !important;
      text-align: left !important;
      top: 10% !important;
      width: 75% !important;
    }
  }
  .site-email-sms-signup {
    .form--newsletter {
      &__form-items {
        #form--email_sms_signup--field--MOBILE_NUMBER {
          display: none;
        }
      }
    }
    height: 55%;
  }
  .homepage-qrcode {
    .homepage-qrcode-img {
      img {
        width: 36%;
      }
      text-align: center;
    }
    background: $color--white;
    height: 45%;
    position: relative;
    padding: 15px;
    border-top: 1px solid $color--gray--lightest;
  }
  .email-signup-new-user {
    .mobile-signup {
      display: none;
    }
  }
  .contact-us-thanks-page {
    margin: 20px 190px;
    .acknowledge {
      margin: 20px auto;
    }
  }
  .contact-us-page {
    .contact-nav {
      li {
        .details {
          min-height: 230px;
          padding-bottom: 10px;
          border-bottom: 1px solid #7f7f7f;
        }
      }
      .js-live-chat {
        display: none;
      }
      .js-call-us,
      .js-customer-service {
        margin-left: 0;
        margin-right: 0;
      }
      .js-email-artist {
        float: left;
      }
    }
  }
  .site-email-sms-signup {
    height: 52%;
  }
  .favorites-page {
    .product__footer {
      .product-item__out-of-stock {
        float: #{$rdirection};
        height: 40px;
        line-height: 40px;
        margin: 0;
      }
    }
  }
  .store-locator {
    .location_info {
      .add-to-favorites {
        display: none;
      }
    }
  }
  #product-page-livechat-hitarea {
    .product-page-livechat-hitarea-mask {
      display: none;
    }
  }
  .search-results {
    .search-filter__trigger {
      @include breakpoint($bp--large-up) {
        top: 85px;
      }
    }
    .pagination {
      margin-bottom: 20px;
    }
  }
  .product-full__media {
    .product-full__image {
      .product-full__carousel-slide {
        width: 93%;
      }
    }
  }
}

.checkout-confirmation-page {
  .checkout-header__title {
    @include breakpoint($bp--medium-up) {
      @include breakpoint($bp--large-up) {
        width: 100%;
        text-align: center;
        padding-right: 0;
        right: 0;
      }
    }
  }
}

.qrWrapper {
  display: none;
}

.waitlist-iframe-wrapper {
  width: 95%;
  height: 190px;
  margin: 17px 7px 5px 7px;
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 20px;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
  .field {
    .email_input {
      float: left;
      width: 79%;
    }
    .align-r {
      float: left;
      margin-left: 10px;
      .input-btn {
        height: 58px;
      }
    }
    .terms_condition_cont {
      float: right;
      margin: 10px 5px 0 0;
    }
  }
}

.site-footer {
  .footer-menu--pc {
    .social-links {
      position: relative;
      #footer-wechat-qrcode {
        position: absolute;
        z-index: 9;
        top: 80px;
        left: 0px;
        box-shadow: 0 0 3px #fff5f5;
      }
    }
  }
  .site-footer--bottom {
    .field-menu {
      ul.menu {
        li {
          font-size: 1rem;
        }
      }
    }
  }
}

.site-header {
  &__menu {
    #site-navigation {
      .site-navigation {
        &__menu.main-menu {
          .site-navigation__carousel,
          .site-navigation__dropdown {
            margin-top: -2px;
          }
        }
      }
    }
  }
}

@include breakpoint($bp--largest-up) {
  .custom-grid .unique-small-snowflake {
    padding-top: 27.5% !important;
    .custom-grid__item-inner {
      .basic-responsive-v1 {
        height: 48%;
        background-color: white;
      }
    }
  }
}

.sign-in-page {
  .new-account__fieldset {
    .registration-page__sms-verification {
      #sms_error {
        line-height: 0;
      }
    }
  }
}

#csr_header_holder {
  z-index: 10000 !important;
}
//Roboto-Mono font replaces the Tstar font
.grid--mpp__item {
  .product--teaser {
    .product_header_details {
      .product_rgn_name_below_subline {
        @if $fonts_update == false {
          font-family: $roboto-mono_regular;
        }
      }
    }
  }
}

.page--spp-ultra-wide {
  .product--full {
    .product_rgn_name_below_subline {
      @if $fonts_update == false {
        font-family: $roboto-mono_regular;
      }
    }
  }
}

.site-container {
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        .product__inventory-status {
          a.notify_me {
            width: auto;
            position: static;
            line-height: 30px;
            font-size: 15px;
            display: inline-block;
            height: 30px;
            float: right;
            clear: none;
            padding: 0px 15px;
            @if $fonts_update == true {
              font-weight: 400;
              letter-spacing: 0;
              margin-bottom: 0.3em;
              margin-top: 0.3em;
              border-bottom: none;
            } @else {
              margin: 0px;
            }
          }
        }
      }
    }
  }
}

@if $fonts_update == true {
  .product__mini-detail {
    .product--teaser & {
      font-size: 12px;
    }
  }
}

a {
  &.product-add-to-bag {
    &:focus {
      color: $color--white;
    }
    &:hover {
      color: $color--gray--white;
    }
  }
}

a[data-clickable] {
  border-bottom: 0;
}

.elc-product-brief {
  .elc-shade-swatches-wrapper {
    .elc-product-shade-picker-title {
      line-height: 1.25;
      margin-bottom: -0.25em;
    }
  }
}
