$border_color: #dbdbdb;
$background_color: #d4d4d4;
$border_valid: #000;

#recommended-products-panel {
  .product_name a {
    border: none;
  }
  .recommended-item {
    margin-top: 10px;
  }
}

.viewcart {
  #order-summary-panel {
    border-top: 1px solid $border_color;
  }
}

#checkout-sidebar {
  .additional_info {
    margin-top: 1em;
  }
}

.samples {
  .product.deactivate {
    .sample-select-button {
      background-color: $background_color;
      cursor: auto;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .picker-checkbox {
    .picker-handle {
      display: none;
    }
  }
  .samples-panel {
    border-top: 1px solid $border_color;
    padding-top: 15px;
  }
  /*  .product-list {
    li {
      float: left;
      width: 32%;
      @include swap_direction(padding, 0 0 10px 0);
      height: 340px;
      img {
        min-height: 163px;
      }
    }
  } */
  .checkout__panel-title {
    display: block;
  }
  .sample-select-button {
    width: 228px;
  }
  .sample-products {
    .skus {
      position: absolute;
      bottom: 0;
      .picker {
        overflow: visible;
      }
    }
  }
}

.shipping .checkout-progress__shipping,
.review .checkout-progress__review,
.billing .checkout-progress__billing,
.confirm .checkout-progress__confirm {
  font-weight: bold;
}

.site-container {
  .checkout {
    &.viewcart {
      .checkout-header {
        .viewcart-header {
          border-bottom: 1px solid $color--gray--lightest;
          h4 {
            padding-right: 0;
            text-align: center;
            right: 0;
            width: 100%;
          }
        }
        #bottom-viewcart-buttons {
          .viewcart-buttons-panel {
            .content {
              width: 74%;
              .continue-buttons {
                margin-top: 10px;
                a.continue-shopping {
                  text-decoration: none;
                  font-size: 18px;
                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }
      .left {
        .viewcart-panel {
          .bottom-viewcart-buttons {
            display: none;
          }
        }
        #bottom-viewcart-buttons {
          .content {
            width: 100% !important;
            .continue-buttons {
              width: 100% !important;
              a.continue-shopping {
                text-decoration: none;
                font-size: 18px;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
    .empty {
      .left {
        .viewcart-panel {
          #promo-message {
            display: none;
          }
          li:last-child {
            padding-bottom: 20px;
          }
          li:first-child {
            padding-top: 20px;
          }
          .empty-cart {
            h4 {
              display: none;
            }
          }
        }
        .bottom-viewcart-buttons {
          .content {
            width: 100% !important;
            .continue-buttons {
              width: 100% !important;
              a.continue-shopping:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
    ol.checkout-progress {
      li {
        float: left;
        margin-left: 1.5em;
        width: 12.5em;
        list-style-type: decimal;
        margin-top: 1.5em;
      }
    }
    .address-container {
      width: 40%;
      float: left;
    }
    .address_controls {
      #choose-address {
        float: left;
        margin-top: 15px;
      }
    }
    address {
      font-style: normal;
    }
    #review-instructions p {
      margin-top: 15px;
    }
    .address-form {
      .city-state-zip {
        .state_select,
        .district_select {
          width: 50%;
          float: left;
          clear: none;
          padding-right: 1em;
        }
        .city_select,
        .postal_code {
          width: 50%;
          float: right;
          margin-left: 0;
          margin-right: 0;
          clear: none;
          padding-right: 0;
        }
      }
      select:required:valid {
        border-color: $border_valid;
      }
    }
    .checkout__sidebar {
      .order-summary__content {
        .label.total,
        .value.total {
          background: transparent;
          padding: 0;
        }
      }
    }
  }
  .checkout__content {
    #shipping-address-info-panel {
      #address_form_container {
        fieldset {
          @include breakpoint($bp--medium-up) {
            max-width: 660px;
          }
        }
      }
    }
    #payment-container {
      #selected_box {
        .verify_code {
          .picker {
            margin: 0em 0em 1em 0em;
          }
          #get_code_btn {
            display: block;
          }
        }
      }
    }
  }
  .order-details-page {
    .product-header {
      .total {
        text-align: right;
      }
    }
    .cart-item {
      .cart-item__total {
        width: 8.33333%;
        float: right;
        margin-right: 0;
        margin-left: 0;
        clear: none;
        padding-right: 0;
        text-align: right;
      }
    }
  }
}

.sign-in-page {
  .select2-container {
    width: 27% !important;
    float: left;
    margin-right: 25px;
  }
}

#delivery-address-info-panel {
  #delivery-instructions {
    textarea {
      width: 40%;
    }
  }
  label {
    display: block;
  }
  .select2-container {
    width: 25% !important;
  }
}

#invoice-info-panel {
  label {
    display: inline-block;
  }
  input {
    width: 30%;
  }
}

#giftwrap-info-panel {
  label {
    display: block;
  }
}

.gift-message {
  .giftmsg_from_container,
  .giftmsg_to_container,
  .giftmsg_message_container {
    width: 48%;
    float: left;
    margin: 0 10px 10px 0;
  }
}

#invoice-options-wrapper {
  .type {
    margin-top: 10px;
  }
}

.trans_detail_container {
  .trans_detail_item {
    float: left;
    margin-bottom: 25px;
    margin-right: 25px;
    position: relative;
    width: 220px;
    word-wrap: break-word;
    h4 {
      border-bottom: 1px solid #ccc;
      font-size: 11px;
      font-weight: bold;
      margin-bottom: 10px;
      padding-bottom: 5px;
      text-transform: none;
    }
    a.change_link {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .mid_column {
    float: left;
    width: 240px;
  }
}

#payment-method {
  margin-bottom: 25px;
  ul#payment_tab {
    height: 40px;
    list-style: outside none none;
    li.selected {
      -moz-border-bottom-colors: none;
      -moz-border-left-colors: none;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      border-color: #5c88e1 #5c88e1 -moz-use-text-color;
      border-image: none;
      border-style: solid solid none;
      border-width: 1px 1px medium;
    }
    li {
      border-bottom: 1px solid #5c88e1;
      border-left: 1px solid #ccc;
      border-top: 1px solid #ccc;
      float: left;
      font-size: 1.2em;
      margin: 0;
      padding: 0.8em 0;
      text-align: center;
      width: 49.5%;
    }
    li:last-child {
      border-right: 1px solid #ccc;
    }
  }
  #selected_box {
    border-bottom: 1px solid #5c88e1;
    border-left: 1px solid #5c88e1;
    border-right: 1px solid #5c88e1;
    clear: both;
    padding: 1px;
    width: 99%;
    ul#banks {
      li {
        float: left;
        margin-bottom: 10px;
        width: 200px;
        margin-left: 1em;
        img {
          border: 1px solid #ccc;
        }
      }
      .picker-radio {
        label.bank_logo {
          display: block !important;
        }
      }
    }
    span {
      margin: 20px;
    }
  }
  #select-payment {
    margin-bottom: 12px;
  }
}

a.disabled.continue-checkout {
  background-color: #ccc;
  &:hover {
    text-decoration: none;
    cursor: default;
  }
}

.shipping {
  .title-container {
    .picker-radio {
      float: left;
      width: 12%;
    }
    label {
      display: inline-block;
    }
  }
  div.birthday_col {
    .select2-container {
      width: 15% !important;
      float: left;
      margin-right: 25px;
    }
  }
}

#payment-container {
  #selected_box {
    padding: 20px;
  }
}

.js-dd-input-state,
.js-dd-input-city {
  width: 48% !important;
}

.select2-drop-mask {
  z-index: 9999;
}

.site-container {
  .checkout__content {
    #top-viewcart-buttons {
      display: none;
    }
    .guarantee-panel {
      border-bottom: none;
    }
    #sign-in-paypal-panel,
    #payment-info-panel,
    #payment-panel {
      border-bottom: none !important;
    }
    .shipping-panel {
      .column {
        &:nth-of-type(2n + 1) {
          width: 50%;
          float: left;
          margin-right: -100%;
          clear: none;
          padding-right: 1em;
          clear: both;
        }
        &:nth-of-type(2n + 2) {
          width: 50%;
          float: right;
          margin-left: 0;
          margin-right: 0;
          clear: none;
          padding-right: 0;
        }
        .gift-options {
          word-wrap: break-word;
        }
      }
    }
    .address-form {
      .title {
        label {
          display: inline-block;
        }
        label:not(.picker-label) {
          margin: 1em 1em 1em 0;
          overflow: hidden;
        }
        label.picker-label {
          margin-bottom: -10px;
          line-height: 30px;
        }
        label[alt='* Title'] {
          display: inherit;
          margin-bottom: 5px;
        }
      }
    }
    .billing-address-form,
    .default-shipping,
    .edit-billing-radio,
    .edit-shipping-radio {
      label {
        display: inline-block;
      }
    }
    .billing-address-from-fields {
      .form-item.title {
        label {
          display: inline-block;
        }
      }
    }
    .product__desc {
      .product__desc-info.loading {
        width: 100%;
        float: none;
        padding-right: 0;
        margin: 0;
        text-transform: lowercase;
      }
    }
    .city-state-zip {
      .postal-code {
        width: 25%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none;
        padding-right: 0;
        input[type='text'] {
          width: 100%;
          margin-bottom: 0px;
        }
      }
      .state {
        select {
          height: 58px;
          text-decoration: none;
        }
        .select2-choice {
          text-decoration: none;
        }
      }
    }
    .form-item.title {
      width: 100% !important;
      .picker {
        display: inline-block;
        label {
          display: inline-block;
          &:first-child {
            display: none;
          }
        }
      }
    }
    .qas {
      .qas_container {
        width: 100%;
        padding-right: 0;
        .address_label {
          display: block;
        }
      }
    }
    .default-shipping {
      margin-bottom: 0px;
      label {
        display: inline-block;
      }
    }
    #bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        .content {
          width: 100%;
          .continue-buttons {
            width: 100% !important;
            padding-right: 10%;
            .btn.continue-checkout {
              border-width: 1px;
              border-color: #000;
            }
          }
        }
      }
    }
    #continue-btn {
      input[type='submit'] {
        line-height: 60px;
        border: none;
      }
    }
  }
  #confirmation-page {
    .checkout-header {
      .checkout-header__title {
        width: 100% !important;
        text-align: center;
        right: 0;
        padding-right: 0;
      }
    }
  }
  .checkout {
    .right {
      .guarantee-panel {
        display: none;
      }
      .order-summary__shipping-content {
        .form-item.ship-method {
          width: 100%;
        }
      }
    }
    .sign-in-panel {
      border-bottom: 1px solid $color--gray--lightest;
      .messages-container {
        padding: 0;
        background-color: transparent;
        border-width: 0;
        .single-message {
          color: $color--error;
          text-align: left;
        }
      }
      .picker-checkbox {
        .label-content {
          position: relative;
          top: 5px;
        }
      }
      &header {
        margin-bottom: 10px;
      }
    }
    &.shipping_content {
      .checkout__content {
        padding-left: 0px;
      }
    }
    .form-item.ship-method {
      text-align: center;
      width: 90%;
      margin-bottom: 0px;
      .select2-choice {
        height: 30px;
        line-height: 30px;
        .select2-chosen {
          margin: 0px;
          width: 87%;
        }
      }
    }
    select::-ms-expand {
      display: none;
    }
    #continue-btn {
      clear: both;
      input.form-submit {
        width: 228px;
      }
    }
    .gift-options__content {
      float: right;
    }
  }
  .checkout__sidebar {
    select::-ms-expand {
      display: none;
    }
  }
  .checkout-header {
    #top-viewcart-buttons {
      width: 100%;
      border-top: 1px solid $color--gray--lightest;
      display: none;
      .viewcart-buttons-panel {
        padding-top: 1em;
        .content {
          width: 100%;
          .continue-buttons {
            margin-top: 10px;
            padding-right: 0;
            text-align: center;
            .btn.continue-checkout {
              border-width: 1px;
              border-color: #000;
            }
          }
        }
      }
    }
    .checkout-header__title {
      width: 100%;
      text-align: center;
      padding-right: 0;
      right: 0;
    }
  }
}

.shipping-panel {
  .edit-shipping-radio,
  .edit-billing-radio {
    .picker {
      display: inline-block;
      label {
        display: inline-block;
      }
    }
  }
  header .edit {
    border: none;
  }
  header .edit.btn {
    float: right;
  }
}

.phones,
.name-fields {
  .first-name,
  .phone-1 {
    input[type='text'] {
      width: 100%;
    }
  }
}

.checkout {
  .ui-widget-content {
    height: 12em;
    overflow-y: auto;
  }
  .recommended-product-items {
    .description {
      padding-bottom: 50px;
      .info {
        height: auto;
        margin-bottom: 30px;
        .formatted_price {
          position: relative;
          bottom: 0;
          margin-bottom: 10px;
        }
      }
    }
    .addtobag {
      position: absolute;
      bottom: 0;
      input[type='submit'] {
        line-height: 60px;
        border: none;
      }
    }
  }
}

.phones,
.city-state-zip {
  .phone-1,
  .phone-2,
  .postal-code {
    input[type='text'].error {
      border-color: $color--error;
    }
  }
}

.section-email-promotions {
  .pc_email_promo_container {
    input[type='text'].error {
      border-color: $color--error;
    }
  }
}

.payment_securepay {
  .payment-container.form-item {
    label {
      display: inline-block;
    }
  }
}

#checkout_payment {
  .payment-form {
    .cvv label {
      display: none;
    }
  }
}

.lpPoweredBy {
  display: none;
}

#shipping-panel {
  &.finished {
    #shipping-address-display,
    #gift-options,
    #billing-address-display {
      float: left;
      width: 33%;
      padding-right: 2%;
    }
  }
  &.adpl {
    .gift-options__content {
      input + label {
        display: block;
      }
    }
    .invoice-options-container {
      .option.name {
        margin-bottom: 10px;
      }
    }
  }
}

.checkout {
  .ui-menu-item {
    a.ui-corner-all {
      padding-left: 12px;
      border: none !important;
      display: block;
    }
  }
  .panel {
    select {
      margin-bottom: 0px;
    }
    .valid_marker {
      display: none;
    }
  }
  .ui-widget-content {
    height: 11.4em;
    font-family: tstar_mono_roundregular, Helvetica, Arial, sans-serif;
    top: 486px;
  }
  .ui-corner-all {
    -webkit-border-radius: 0px;
    border-radius: 0px;
  }
}

#offer_code {
  .offer-code__submit {
    input[type='submit'] {
      line-height: 60px;
    }
  }
}
/* Checkout index.tmpl internal styles moved and modified to scss */
#selected_box {
  .payone {
    margin: 20px 30px 0 30px;
    img {
      margin: -55px -10px 0 0;
    }
  }
  .paywy_text {
    margin: 38px 20px 20px 34px;
    font-size: 12px;
  }
  .paytwo {
    clear: both;
    margin-left: 40px;
    margin-bottom: 20px;
    span {
      margin: 0;
    }
    label {
      float: none;
      display: inline-block;
      vertical-align: bottom;
    }
    .payt_left,
    .payt_right {
      display: inline-block;
      vertical-align: top;
    }
    .payt_left {
      width: 53%;
    }
    .payt_right {
      width: auto;
    }
    .btn_rad {
      display: inline-block;
      margin: 55px 3px 0 -2px;
      vertical-align: top;
    }
    .use_pc {
      float: left;
      max-width: 175px;
    }
    .use_img {
      float: left;
      display: inline;
    }
    .picker-handle {
      margin-top: 30px !important;
    }
  }
  label {
    color: #555;
  }
}

#form--checkout_review--field--PAYMENT_OPTION--index--payment_option_chinapay_label {
  img {
    vertical-align: middle;
    display: inline-block;
  }
}

#payment-container {
  #form--checkout_review--field--PAYMENT_OPTION--index--payment_option_chinapay {
    vertical-align: middle;
    margin-top: 20px;
    margin-left: 40px;
  }
  #form--checkout_review--field--PAYMENT_OPTION--index--payment_option_cod_label {
    padding-left: 40px;
    padding-top: 5px;
  }
  #form--checkout_review--field--PAYMENT_OPTION--index--payment_option_chinapay_label {
    margin-left: 10px;
  }
  .use_pc {
    h5 {
      font-size: 12px;
    }
  }
}

#review {
  .overlay-background {
    background-color: white !important;
    height: 100%;
    width: 100%;
  }
}

.checkout {
  .picker-handle {
    margin-top: 5px !important;
  }
}

.ie {
  #payment-container {
    #form--checkout_review--field--PAYMENT_OPTION--index--payment_option_cod {
      margin-bottom: -35px !important;
    }
  }
}

.ie {
  #payment-container {
    #form--checkout_review--field--PAYMENT_OPTION--index--payment_option_cod_label {
      margin-left: 30px !important;
    }
  }
}

#payment-panel {
  #payment-container {
    li {
      list-style: none;
    }
  }
}

#shipping-panel {
  .row {
    .col-6 {
      float: right;
      padding: 5px 0;
      width: 50%;
    }
  }
  .row.panel-group {
    div {
      margin-right: 0;
      width: 50% !important;
    }
  }
  .gift-options__content {
    label {
      display: inline;
    }
  }
}

.sms_code_container {
  input[type='button'] {
    height: 40px;
    line-height: 40px;
    margin: 10px 0;
  }
}

#payment-method {
  #selected_box {
    .bind_mobile_option {
      span {
        margin: 0px;
      }
    }
  }
}

.adpl {
  .address-form {
    .district_select,
    .district {
      label {
        display: none;
      }
    }
  }
}

.opc__signin,
.opc__shipping,
.opc__payment {
  .checkout__content {
    .order-summary-panel {
      display: none;
    }
  }
}

#completed_transaction_signin {
  .form-item.password {
    input {
      margin-top: 10px;
    }
  }
}

#site-header {
  .site-header {
    &__prefix {
      width: 10%;
    }
    &__menu {
      width: 65%;
    }
    &__suffix {
      width: 20%;
    }
  }
  .site-navigation {
    &__item {
      padding: 0 15px;
    }
  }
}
