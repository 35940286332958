$pc_account_leftline: 51.44444%;

@mixin error_field_red {
  border: 1px solid red;
  box-shadow: none;
}

.profile-info__fieldset {
  .picker-radio {
    float: left;
    width: 17%;
  }
  .title-form {
    width: 80% !important;
  }
  .code_container {
    input.field {
      width: 52%;
    }
  }
  .sms_code_container {
    input[type='text'] {
      margin-top: 10px;
      height: calc(4.8335em - -2px);
    }
  }
  .title_col,
  .gender_col,
  .sms_col,
  .birthday_col {
    label {
      display: block;
    }
  }
  div.birthday_col {
    .select2-container {
      width: 318px !important;
      float: left;
      margin-right: 25px;
      margin-bottom: 15px;
    }
    .change-birth-text {
      color: $color--red;
    }
  }
  .profile_sms_bound {
    margin-bottom: 10px;
  }
}

.default_shipping {
  input {
    float: left;
    margin: 3px 5px 0 0;
  }
  label {
    display: block;
  }
}

.address-title {
  padding-bottom: 15px;
  label {
    display: block;
    float: left;
    &:first-child {
      float: none;
    }
  }
  input {
    float: left;
  }
}

.confirm-panel {
  #print-order {
    margin-top: 15px;
  }
}

.sign-in-page {
  .return-user {
    .return-user__item {
      input.error {
        @include error_field_red;
      }
    }
  }
  .new-account__fieldset {
    #registration_short {
      .new-account__item {
        input.error {
          @include error_field_red;
        }
      }
    }
  }
  .birth_item {
    width: 80% !important;
  }
  .sms_col,
  .sms_verify_button {
    margin-top: 10px;
  }
  .error_messages {
    margin-bottom: 10px;
  }
}

.order-details-page {
  .cart-item {
    .cart-item__desc {
      margin-left: 25%;
    }
    .cart-item__price {
      margin-left: 50%;
    }
    .cart-item__qty {
      margin-left: 72%;
    }
  }
  .product-header {
    .price {
      margin-left: 50%;
    }
    .quantity {
      margin-left: 72%;
    }
  }
  #logistics-details {
    width: 50%;
    .logistics-details {
      background-color: $color--gray--lightest;
      padding: 5px;
      table {
        width: 100%;
      }
      .date,
      .weekday,
      .time {
        width: 15%;
      }
    }
  }
}

#checkout_samples {
  .sample-products {
    ul.product-list {
      li.product {
        .details {
          ul li.sku {
            height: auto;
          }
        }
      }
    }
  }
}

.site-header {
  &__tools {
    .block-template-site-my-mac-v2 {
      display: inline;
      float: right;
      position: absolute;
      right: 50px;
      @include breakpoint($bp--largest-up) {
        position: relative;
        right: auto;
        width: 41%;
      }
    }
  }
}

.site-container {
  .past-purchases-page,
  .account-page {
    .product--teaser {
      margin-bottom: 25px;
      .product__footer {
        a.notify_me {
          margin: 0px;
          padding: 0px 15px !important;
          line-height: 30px;
        }
        .product-item {
          &__add-to-cart p {
            margin-top: 40px;
            position: absolute;
            right: 0;
            line-height: 1.5;
          }
        }
      }
    }
  }
}

label.error.picker-label {
  color: #b84947;
}

.past-purchases {
  .product {
    &__header {
      z-index: 1;
      .product-item__sub-line {
        font-size: 18px;
        font-family: $ano-bold_regular-font;
        padding-right: 80px;
      }
      .product {
        &__rating-non {
          white-space: nowrap;
          line-height: 24px;
        }
      }
      .product {
        &__rating-stars {
          top: 6px;
        }
      }
    }
  }
  .shipments-list {
    .product-item {
      &__sub-line {
        .product-name {
          &__sub-line {
            font-size: 18px;
            font-family: $ano-bold_regular-font;
          }
        }
      }
    }
  }
  .product {
    &__footer {
      .product-item {
        &__add-to-cart {
          .product-item {
            &__out-of-stock {
              top: 18px;
            }
            &__sold-out,
            &__inactive {
              top: -10px;
            }
          }
        }
      }
    }
  }
}

.recommended-product-items {
  .recommended-item {
    .produce_subname {
      a {
        border: none;
      }
    }
  }
}

.profile-page {
  #profile_preferences {
    .panel {
      &.field-container {
        .form-submit {
          width: 318px;
          border: none;
        }
      }
    }
  }
}

.address-book-page {
  &__content {
    .address-book {
      .address-book {
        &__link {
          width: 230px;
        }
      }
    }
  }
}

.account-order-history {
  .past-purchases {
    .past-purchases__item {
      padding: 1.5em 0;
      .trans-id {
        margin-left: 25.2%;
      }
      .order-status {
        float: none;
        margin-left: $pc_account_leftline;
      }
      .carrier_name {
        margin-left: $pc_account_leftline;
      }
      .order-cost {
        margin-left: 56.66667%;
      }
      .tracking-link {
        margin-left: $pc_account_leftline;
      }
    }
  }
}

.adpl input.error {
  @include error_field_red;
}

#sendSMSCode {
  margin: 20px 0px;
}

#site-header {
  .site-header {
    &__prefix {
      width: 10%;
    }
    &__menu {
      width: 65%;
    }
    &__suffix {
      width: 20%;
    }
  }
  .site-navigation {
    &__item {
      padding: 0 15px;
    }
  }
}

.loyalty-index-page__content {
  .profile-info-panel {
    margin-bottom: 60px;
    .loyalty-customer-info {
      font-size: 2.5em;
      font-weight: bolder;
      padding-left: 20%;
    }
  }
  .loyalty-progress-bar {
    background-image: url(/account/loyalty/progress_bar.tmpl);
    background-size: 100% 100%;
    background-position: 0.5em 0;
    background-repeat: no-repeat;
    min-height: 360px;
    font-weight: bolder;
    text-align: center;
    .loyalty-text-pts-1 {
      font-size: 4em;
      margin-top: 2em;
    }
    .loyalty-text-pts-2 {
      font-size: 3em;
    }
    .loyalty-text-pts-3 {
      font-size: 1.2em;
      font-weight: bold;
      margin-top: 4.75em;
    }
  }
  .profile-error {
    text-align: center;
  }
}
