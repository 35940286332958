#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          .pr-rd-search-container {
            .pr-rd-search-reviews-input {
              input {
                background-color: $color--white;
              }
              .pr-rd-search-reviews-icon-button {
                border: 0;
              }
            }
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: '有帮助(';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: '无用(';
              }
            }
          }
          .pr-rd-flag-review-container {
            margin-right: 12px;
            line-height: 1.5;
          }
        }
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          .pr-rd-to-top {
            padding-left: 41%;
          }
        }
      }
    }
    .pr-filter-btn:before,
    .pr-filter-btn:after {
      display: none;
    }
  }
}

.product__footer {
  .pr-snippet-stars-reco-inline {
    .pr-snippet-stars-reco-stars {
      .pr-snippet-read-and-write {
        .pr-snippet-review-count {
          &:before {
            content: ' ';
          }
        }
      }
      .pr-snippet-stars-container {
        &:before {
          content: '整体评级:';
        }
      }
    }
  }
}

.spp_reviews {
  display: none;
}
